<template>
  <div class="treecont">
    <div style="margin-left:-15px;margin-bottom:15px">
        <Headbuttom></Headbuttom>
    </div>
    <!--        draggable -->
    <div style="border:1px solid #ccc;padding-right:20px">
      <el-tree
          :props="props"
          :data="tableData"
          node-key="id"
          :expand-on-click-node="false"
          default-expand-all
          @node-drag-start="handleDragStart"
          @node-drag-enter="handleDragEnter"
          @node-drag-leave="handleDragLeave"
          @node-drag-over="handleDragOver"
          @node-drag-end="handleDragEnd"
          @node-drop="handleDrop"
        :allow-drag="allowDrag">
        <div class="custom-tree-node" slot-scope="{ node, data }">
            <div class="spans">
              <span>{{ node.label }}</span>
            </div>
            <div class="buttoms">
              <!-- <el-button
                      type="primary"
                      size="mini"
                      @click="() => Settosuperior(data)">
                设置到上级
              </el-button> -->
              <!-- <el-button
                      type="primary"
                      size="mini"
                      @click="() => Settosubordinate(data)">
                设置到下级
              </el-button> -->
              <el-button
                    
                     :disabled="data.is_valid=='1'?true:false "
                      type="primary"
                      size="mini"
                      @click="() => append(data)">
                {{$t('tablename.zj')}}
              </el-button>
              <el-button
                      :disabled="data.is_valid=='1'?true:false "
											v-if="data.parent_id==0?false:true"
                      type="warning"
                      size="mini"
                      @click="() => remove(node, data)">
                {{$t('tablename.sc')}}
              </el-button>
                <el-button
                        :disabled="data.is_valid=='1'?true:false "
                        type="success "
                        size="mini"
                        @click="() => edit(node, data)">
                {{$t('tablename.bjH')}}
              </el-button>
              <!-- 添加 -->
              <el-dialog :title="$t('devtable.tjzbm')" append-to-body width="30%"  :visible.sync="dialogFormVisible">
                <el-form :model="form">
                  <el-form-item :label="$t('devtable.zbmmc')" :label-width="formLabelWidth">
                    <el-input v-model="form.deptNameInput" autocomplete="off"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="handialogFormVisible(data)">{{$t('tablename.qd')}}</el-button>
                </div>
              </el-dialog>
              <!-- 编辑 -->
              <el-dialog :title="$t('devtable.bjbm')" append-to-body width="30%"  :visible.sync="edialogFormVisible">
                <el-form :model="form">
                  <el-form-item :label="$t('devtable.bmmc')" :label-width="formLabelWidth">
                    <el-input v-model="form.ediNameInput" autocomplete="off"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="handialogFormVisibledit(data)">{{$t('tablename.qd')}}</el-button>
                </div>
              </el-dialog>
              <!-- 删除时弹出 -->
              <el-dialog :title="$t('devtable.tsxx')" append-to-body width="30%"  :visible.sync="dialogname">
                <el-form :model="delform">
                  <el-form-item :label="$t('devtable.yhm')" :label-width="formLabelWidth">
                    <el-input v-model="delform.user_name_input" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('devtable.mm')" :label-width="formLabelWidth">
                    <el-input v-model="delform.user_pass_input" autocomplete="off"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="delEEStructDeptName(node,data)">{{$t('tablename.qd')}}</el-button>
                </div>
              </el-dialog>
            </div>
        </div>
    </el-tree>
    </div>
  </div>
</template>
<script>

import methodlist from '../../utils/methods'

export default {
  data () {
    return {
      edialogFormVisible:false,
      props: {
        label: 'name',
        children: 'children'
      },
      flag: true,
      tableData: [],
      cuutenId: null,
      treeinput: '',
      form: {
        deptNameInput: '',
        ediNameInput:''
      },
      delform: {
        user_name_input: '',
        user_pass_input: ''
      },
      dialogFormVisible: false,
      dialogname:false,
      formLabelWidth: '160px',
      Startprant_id:'',
      // 添加的栏目的父id
      addpranid:null,
      // 编辑栏目的id
      editid:null,
      // 拖拽 原父级的id
      tzoldprandid:null,
      UserInfo:'',
	  delId:''
    }
  },
  mounted () {
    this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
    this.getDeptDataList();
  },
  methods: {
    // 编辑
    edit (node, data) {
      this.edialogFormVisible=true
      this.form.ediNameInput = data.name
      this.editid = data.id
    },
    // 编辑确定
    handialogFormVisibledit(){
      let data = {
        method:'co.dept.edit',
        name:this.form.ediNameInput,
        id:this.editid
      }
      this.$serve(data).then(res=>{
        if(res.data.code==0){
          this.$message({
            message: this.$t('devtable.xgcg'),
            type: 'success'
          });
          this.getDeptDataList()
        }else{
          this.$message({
            message: this.$t('devtable.xgsb'),
            type: 'error'
          });
        }
        this.edialogFormVisible=false
        this.form.ediNameInput = ""
      })
      this.getDeptDataList()
    },
    // 编辑后
    hanldsur (data) {
      if(this.treeinput){
          this.cuutenId = ''
          data.name = this.treeinput
      }else{
        this.cuutenId=""
      }
    },
    // 设置到上级
    Settosuperior(data){
        console.log(`11`,data)
        // this.setDeptLevel(data)
    },
    // 设置到下级
    Settosubordinate(data){
      console.log(`22`,data)
      this.setDeptLevel(data)
    },
    // 删除
    remove (node, data) {
      this.dialogname=true   
				 console.log(data)
		this.delId = data.id
    },
    // 增加
    append (data) {
      this.dialogFormVisible = true
      console.log(data,`7`)
      this.addpranid = data.id
    },
    handialogFormVisible (data) {
      // console.log(children)
      this.addNewDept(data);
      //this.dialogFormVisible = false
    },
    // 节点开始拖拽时触发的事件
    handleDragStart (node, ev) {
      console.log('ttt', node)
      // this.Startprant_id=node.data.prant_id
    },
    // 节点拖入其他节点触发
    handleDragEnter (draggingNode, dropNode, ev) {
      // console.log('7777', draggingNode.data.parent_id)
      // // 拖拽 原父级的id
      this.tzoldprandid = dropNode.data.parent_id
    },
    // 拖拽离开某个节点时触发的事件
    handleDragLeave (draggingNode, dropNode, ev) {
      console.log(`666`,draggingNode)
      //console.log('tree drag leave: ', dropNode.label)
    },
    // 在拖拽节点时触发的事件（类似浏览器的 mouseover 事件）
    handleDragOver (draggingNode, dropNode, ev) {
      //console.log('tree drag over: ', dropNode.label)
    },
    // 拖拽结束时（可能未成功）触发的事件
    handleDragEnd (draggingNode, dropNode, dropType, ev) {
      this.tableData
      if(this.tzoldprandid !== dropNode.parent.data.id){
          return false;
      }

      // console.log('8881 ', dropNode) //37 长沙
      // console.log('8882 ', dropType)
      // console.log('8883 ', ev)
    },
    // 拖拽成功完成时触发的事件
    handleDrop (draggingNode, dropNode, dropType, ev) {
      console.log(`000`,dropNode)
      // console.log('tree drop: ', draggingNode,dropNode, dropType)
      //this.setDeptLevel();
    },
    // 判断节点能否被拖拽
    allowDrag (draggingNode) {
      // console.log(draggingNode)
      if (draggingNode.data.name === null) {
        this.$message({
          message: '该职位不能移动',
          type: 'warning'
        })
      } else {
        return true
      }
    },
    //获取企业架构
    getDeptDataList(){
      const params = {
          method: methodlist.ee_dept_list,
          agent_id: this.UserInfo.agent_id,
          manager_id:this.UserInfo.manager_id,
      }
      this.$serve(params).then(res => {          
          console.log(`67`,res);
          var result = [];
          if(res.data.data != null)
            result.push(this.getFirstObj(res.data.data));
          this.tableData = result;
      });
    },
    //增加新部门
    addNewDept(data){
      const params = {
          method: methodlist.ee_dept_add,
          agent_id: this.UserInfo.agent_id,
          name:this.form.deptNameInput,
          parent_id:this.addpranid,
          manager_id:this.UserInfo.manager_id,

      }
      this.$serve(params).then(res => {          
          console.log(res);
          if(res.data.code == 0){
            this.$message({
              message: this.$t('devtable.tjcg'),
              center: true,
              type: 'success',
              duration:2000,
              offset:100
            });
            this.dialogFormVisible = false
            this.getDeptDataList();
          }else{
            //添加失败
              if(res.data.code == -4){
                this.$message({
                  message: this.$t('devtable.tjsbbmycz'),
                  center: true,
                  type: 'warning',
                  duration:2000,
                  offset:100
                });
              }
          }
      });
    },
    //修改部门名称
    // saveEditDept(){
    //   const params = {
    //       method: methodlist.ee_dept_edit,
    //       id:id,
    //       name:'',
    //   }
    //   this.$serve(params).then(res => {          
    //       console.log(res);
    //       if(res.data.code == 0){
    //         this.$message({
    //           message: '修改成功!',
    //           center: true,
    //           type: 'success',
    //           duration:2000,
    //           offset:100
    //         });            
    //       }else{
    //         //修改失败提示
    //       }
    //   });
    // },
    //删除部门
    delEEStructDeptName(node,data){
      console.log("why");
			console.log((data))
      if(this.delform.user_name_input != this.UserInfo.user_name_save){
            this.$message({
              type: 'warning',
              message: this.$t('devtable.yhmsryw'),
            })
            return false;
      }

      if(this.delform.user_pass_input != this.UserInfo.user_pass_save){
            this.$message({
              type: 'warning',
              message:  this.$t('devtable.yhmmsryw'),
            })
            return false;
      }
      const params = {
          method: methodlist.ee_dept_del,
          name: this.delform.user_name_input,
          password: this.$md5(this.delform.user_pass_input),
          id: this.delId
      }
      this.$serve(params).then(res => {          
          console.log(res);
          if(res.data.code == 0){
            const parent = node.parent
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.id === this.delId)
            children.splice(index, 1)
            this.$message({
              message: this.$t('devtable.sccg'),
              center: true,
              type: 'success',
              duration:2000,
              offset:100
            })    
								 this.getDeptDataList();  
          }else{
            //删除失败
            if(res.data.code == -8){
              this.$message({
                message: this.$t('devtable.scsbmyqx'),
                type: 'success'
              }) 
            }else if(res.data.code == -5){
              this.$message({
                message: '设备或人员已关联，请先清除关联',
                type: 'success'
              }) 
            }
          }
      });

      this.dialogname=false      
    },
    //部门设置上下级
    setDeptLevel(data){
      const params = {
          method: methodlist.ee_dept_level_set,
          id:data.id,
          parent_id:data.parent_id
      }
      this.$serve(params).then(res => {          
          console.log(res);
          if(res.data.code == 0){
            this.$message({
              message:this.$t('devtable.szcg'),
              center: true,
              type: 'success',
              duration:2000,
              offset:100
            }); 
            this.getDeptDataList();          
          }else{
            //设置失败原因及提示
          }
      });
    },
    getFirstObj(obj){
      for(var key in obj){
          return obj[key];
      }      
    }
  }
}
</script>
<style lang="less" scoped>
.treecont{
  padding: 30px;
  background-color: #fff;
}
.custom-tree-node{
  display: flex;
  width: 100%;
  .spans{
    flex: 1;
    .inputtr{
      width: 20%;
    }
  }
}
.el-form{
  width: 90%;
}
.el-tree /deep/ .el-tree-node__content{
  height: 50px;
  font-size: 16px;
  border-bottom: 1px solid #ebeef5;
}
.el-tree /deep/ .el-tree-node__content .el-icon-caret-right:before{
    content: "\e78a";
    font-size: 24px;
}
.el-tree /deep/ .el-tree-node__expand-icon.expanded {
    transform: rotate(0deg);
}
.el-tree /deep/ .el-tree .el-icon-caret-right:before {
  content: "\e78a";
}
.el-tree  /deep/ .el-tree-node__expand-icon.expanded.el-icon-caret-right:before{
  content:"\e784";
}
</style>
